<template>
  <div class="container">
    <div class="topbackclass"></div>
    <div class="centerclass">
      <div class="centerchild">
        <div class="logoclass">
          <img src="../../assets/login_logo.png" alt="" />
        </div>
        <div class="inputmsgclass">
          <input
            v-model="phone"
            class="shoujihao"
            type="number"
            name=""
            id=""
            placeholder="请输入手机号"
          />
        </div>
        <div v-show="isCodeOrPass" class="inputmsgclass inputmsgclass2">
          <input
            v-model="code"
            class="shoujihao"
            type="number"
            name=""
            id=""
            placeholder="请输入验证码"
          />
          <div
            :style="
              getcode == '获取验证码' ? '' : 'width:2.3rem;color:#909399;'
            "
            @click="cligetcode"
          >
            {{ getcode == "获取验证码" ? getcode : `${getcode}s后可重新重发` }}
          </div>
        </div>
        <div v-show="!isCodeOrPass" class="inputmsgclass inputmsgclass2">
          <input
            v-model="upwd"
            class="shoujihao"
            :type="canShowPass ? 'text' : 'password'"
            name=""
            id=""
            placeholder="请输入密码"
          />
          <van-icon
            @click="canShowPass = !canShowPass"
            style="margin-right: 0.1rem; width: 0.38rem"
            color="#909399"
            size=".4rem"
            :name="canShowPass ? 'eye-o' : 'closed-eye'"
          />
        </div>
        <div class="fangshiclass">
          <div></div>
          <div
            @click="forget"
            v-show="!isCodeOrPass"
            style="color: #909399"
          >
            忘记密码
          </div>
        </div>
        <div
          @click="loginbtn"
          :class="cantologin ? 'loginclass2' : 'loginclass'"
        >
          {{ isCodeOrPass == true ? "验证码授权登录" : "密码授权登录" }}
        </div>
        <div @click="isCodeOrPass = !isCodeOrPass" class="loginmethclass">
          {{ isCodeOrPass == true ? "密码授权登录" : "验证码授权登录" }}
        </div>
      </div>
    </div>
    <!-- <div class="title">登录</div> -->

    <!-- <div class="tip">实名认证后，您被企业入职的机率会更高</div> -->
    <van-loading v-if="showLoading" size="45" class="loading" type="spinner" />
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      type: 0,
      code: "",
      mobileCode: "发送验证码",
      codeStatus: true,
      interval: "",
      isRequest: true,
      phone1: "",
      phone2: "",
      phone: "",
      upwd: "",
      isCodeOrPass: true,
      showLoading: false,
      getcode: "获取验证码",
      cantologin: false,
      canShowPass: false,
    };
  },
  watch: {
    phone(newmsg) {
      if (/^1[3-9]\d{9}$/.test(newmsg)) {
        this.cantologin = true;
      } else {
        this.cantologin = false;
      }
    },
    code(oldmsg) {
      if ((oldmsg + "").length > 6) {
        this.code = (oldmsg + "").substr(0, 6);
      }
    },
  },
  methods: {
    /**
     * 计时器
     */
    timeing() {
      this.getcode = 60;
      this.intervalID = setInterval(() => {
        this.getcode--;

        if (this.getcode < 1) {
          clearInterval(this.intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 停止计时器
     */
    destroyed() {
      clearInterval(this.intervalID);
    },

    /**
     * 获取验证码
     */
    cligetcode() {
      if (this.getcode != "获取验证码") {
        return;
      }
      if (this.phone == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }

      this.timeing();

      //获取验证码
      this.sendcode();
    },

    /**
     * 发送验证码
     */
    sendcode() {
      this.$http
        .post("/user/v1/login/validSend", {
          reqType: "validSend",
          phone: this.phone,
        })
        .then(() => {
          this.$toast("发送成功");
        })
        .catch(() => {});
    },
    blur() {
      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let bool = reg.test(this.phone2);
      if (bool == false) {
        Toast.fail("请输入正确的手机号");
      }
    },
    selectLogin(type) {
      this.type = type;
    },
    loginbtn() {
      if (!this.isCodeOrPass) {
        if (this.phone == "") {
          Toast("请输入手机号");
          return;
        }
        if (this.upwd == "") {
          Toast("请输入密码");
          return;
        }
        //账号密码登录
        this.showLoading = true;
        this.$http
          .post("/user/v1/Login/login", {
            reqType: "login",
            phone: this.phone,
            password: this.upwd,
          })
          .then((res) => {
            res = JSON.parse(res.data);
            // console.log(res)
            if (res.code == 0) {
              this.showLoading = false;
              localStorage.setItem("id", res.data.userInfo.id);
              localStorage.setItem("token", res.data.userInfo.token);
              let userInfo = JSON.stringify(res.data.userInfo);
              localStorage.setItem("userinfo", userInfo);
              this.$router.push("/message");
            } else {
              this.showLoading = false;
              Toast.fail(res.msg);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if (this.phone == "") {
          Toast("请输入手机号");
          return;
        }
        if (this.code == "") {
          Toast("请输入验证码");
          return;
        }

        this.showLoading = true;
        this.$http
          .post("/user/v1/Login/phoneLogin", {
            reqType: "phoneLogin",
            phone: this.phone,
            mobileCode: this.code,
          })
          .then((res) => {
            let response = JSON.parse(res.data);
            // console.log(response.msg)
            if (response.data == "") {
              this.showLoading = false;
              Toast.fail(response.msg);
            } else {
              this.showLoading = false;
              localStorage.setItem("id", response.data.userInfo.id);
              localStorage.setItem("token", response.data.userInfo.token);
              let userInfo = JSON.stringify(response.data.userInfo);
              localStorage.setItem("userinfo", userInfo);
              this.$router.push("/message");
              Toast.success("登录成功");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    forget() {
      this.$router.push("./editUpwd");
    },
    sendCode() {
      let { phone1 } = this;
      if (phone1 == "") {
        Toast("请确认输入手机号");
        return;
      }
      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let bool = reg.test(phone1);
      if (bool == false) {
        Toast.fail("请输入正确的手机号");
        return;
      }
      var count = 60;
      this.codeStatus = false;
      if (this.isRequest == true) {
        this.isRequest = false;
        this.interval = setInterval(() => {
          count--;
          this.mobileCode = `${count}s后重新发送`;
          if (count == 0) {
            clearInterval(this.interval);
            count = 60;
            this.codeStatus = true;
            this.mobileCode = "发送验证码";
            this.isRequest = true;
          }
        }, 1000);
        this.$http
          .post("/user/v1/login/validSend", {
            reqType: "validSend",
            phone: phone1,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.loginmethclass {
  font-size: 0.28rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 0.28rem;
}
.loginclass {
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.44rem;
  text-shadow: 0px 0px 0px rgba(249, 137, 53, 0.59);
  padding: 0.26rem 0;
  text-align: center;
  background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
  box-shadow: 0rem 0.08rem 0.16rem 0rem rgba(249, 137, 53, 0.59);
  border-radius: 0.48rem;
  opacity: 0.6;
  margin-top: 0.5rem;
}
.loginclass2 {
  text-align: center;
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.44rem;
  text-shadow: 0px 0px 0px #ffceaa;
  padding: 0.26rem 0;
  background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
  box-shadow: 0rem 0.08rem 0.16rem 0rem #ffceaa;
  border-radius: 0.48rem;
  margin-top: 0.5rem;
}
.loginclass2:active {
  transform: scale(0.9);
}
.fangshiclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: rgba(69, 113, 208, 0.9);
  line-height: 0.48rem;
  padding-top: 0.2rem;
  padding-left: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputmsgclass2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputmsgclass2 div {
  width: 1.6rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.48rem;
}
.inputmsgclass2 input {
  flex: 1;
}
.inputmsgclass {
  padding: 0.28rem 0;
  border-bottom-width: 1px;
  border-bottom-color: #efefef;
  border-bottom-style: solid;
}
.shoujihao {
  width: 100%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
  border: none;
}
.logoclass {
  text-align: center;
  margin-bottom: 0.58rem;
}
.logoclass img {
  width: 4.34rem;
  margin: 0;
}
.xieyi {
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 1vh;
}
.xieyi span {
  color: #fe5903;
}
.centerclass {
  background-color: #ffffff;
  height: 70vh;
  margin: 0 0.24rem;
  border-radius: 0.3rem;
  margin-top: -15vh;
  padding: 5vh 7vw;
}
.topbackclass {
  height: 30vh;
  background-image: url("../../assets/login_back.png");
  background-size: 100% 100%;
}
.dl {
  position: absolute;
  top: 4.1rem;

  left: 0.8rem;
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ef811a;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.2rem;
}
.title {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ef811a;
  text-align: center;
  margin-top: 0.2rem;
}
.img {
  width: 4rem;
  margin-top: 1rem;
}
.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f6f6f7;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
}
.lbls {
  margin-top: 0.5rem;
}
.login {
  width: 3.5rem;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 0.2rem;
  margin-top: 0.5rem;
  margin-left: 3.5rem;
}
.loginTxt {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55613;
  border-bottom: 0.03rem solid #f55613;
  padding-bottom: 0.2rem;
}
.noactive {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.input {
  width: 6rem;
  height: 1.5rem;

  background: #ffffff;
  border: none;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  /* color: ; */
  border-bottom: 0.01rem solid #ddd;
}
.getcode {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c9ced6;
}
.getcodeactive {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ef811a;
}
.loginbtn {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  line-height: 0.96rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 2rem auto 1rem;
  /* position:absolute;
	bottom:0.4rem;
	left:0.25rem */
}
.tip {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  text-align: left;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f55814;
}
.forget {
  float: right;
  margin-right: 0.8rem;
  margin-top: 0.2rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ef821a;
}
</style>